@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Space+Grotesk:wght@300..700&display=swap");

$primary-color: #ca95ff;
$secondary-color: #ffffff;
$background-color: #1b0033;

body {
	font-family: "Space Grotesk", sans-serif;
	width: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background: radial-gradient(circle at 50% 150%, #6606af 30%, #210034 70%);
	color: #e3b1ff; /* Sustituyendo $secondary-color */
	font-family: "Space Grotesk", sans-serif;
}

.header {
	text-align: center;

	h1 {
		font-family: "Press Start 2P", cursive;
		color: transparent;
		-webkit-text-stroke: 0.1px $primary-color;
		font-size: 3rem;
		margin-bottom: 1rem;
		text-align: center;
		line-height: 40px;
	}

	.countdown {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		margin: 40px 0px;

		.time-box {
			#text {
				margin-top: 30px !important;
			}
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: transparent; /* Interior del texto transparente */
			-webkit-text-stroke: 0.1px $primary-color; /* Contorno definido */
			border: 1px solid $primary-color;
			box-shadow: #ffffff63 0px 0px 6px;
			background-color: #ffffff12;
			padding: 2rem 2rem 1rem 2rem;
			font-size: 1.5rem;
			font-family: "Press Start 2P", cursive;
			font-weight: bold;
			.subtext {
				font-size: 1.2rem;
			}
		}
	}
}

.main-content {
	.status-container {
		width: 400px;
		h1 {
			font-family: "Press Start 2P", cursive;
			color: transparent;
			-webkit-text-stroke: 0.1px $primary-color;
			font-size: 1.5rem;
			margin-bottom: 1rem;
			text-align: center;
			line-height: 30px;
		}
		p {
			margin-top: 30px;
			font-size: 0.8rem;
			font-family: "Press Start 2P", cursive;
			line-height: 23px;
		}
		padding: 2rem;
	}

	text-align: center;
	border: 1px solid $primary-color;
	background-color: #ffffff12;
	box-shadow: #ffffff63 0px 0px 6px;

	.email-section {
		display: flex;
		padding: 4rem 6rem;
		.email-input::placeholder {
			color: #ffffffb5;
		}
		input[type="search"] {
			-webkit-appearance: none;
		}
		.email-input {
			color: #f8deff;
			padding: 1rem;
			margin-right: 4px;
			border: 1px solid #dfbfff8f;
			outline: none;
			font-size: 1rem;
			font-family: "Space Grotesk", sans-serif;
			width: 300px;
			background-color: #ffffff38;
			transition: all 0.5s ease-in-out;
			border-radius: 0;

			&:focus {
				border-color: $primary-color;
				border: 0.1px solid #ffffff;
			}
		}

		.subscribe-button {
			position: relative;
			padding: 1rem 2rem;
			background: transparent;
			color: #f8deff;
			border: none;
			font-size: 1rem;
			font-family: "Press Start 2P", cursive;

			cursor: pointer;
			text-transform: uppercase;
			letter-spacing: 2px;
			overflow: hidden;
			transition: all 0.5s ease-in-out;

			&:hover {
				background-color: #691d8f;
				color: #ffffff;
			}
		}

		.subscribe-button::before {
			content: "";
			position: absolute;
			top: 2px;
			left: 2px;
			bottom: 2px;
			width: 100%;
		}

		.subscribe-button span:nth-child(1) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: linear-gradient(to right, #ffffff, #e676ff);
			box-shadow: #ffffff63 0px 0px 6px;

			animation: animate1 4s linear infinite;
			animation-delay: 2s;
		}

		.subscribe-button span:nth-child(2) {
			position: absolute;
			top: 0;
			right: 0;
			width: 1px;
			height: 100%;
			background: linear-gradient(to right, #ffffff, #e676ff);
			box-shadow: #ffffff63 0px 0px 6px;
			animation: animate2 4s linear infinite;
			animation-delay: 4s;
		}

		.subscribe-button span:nth-child(3) {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: linear-gradient(to right, #ffffff, #e676ff);
			box-shadow: #ffffff63 0px 0px 6px;
			animation: animate3 4s linear infinite;
			animation-delay: 2s;
		}

		.subscribe-button span:nth-child(4) {
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 100%;
			background: linear-gradient(to right, #ffffff, #e676ff);
			box-shadow: #ffffff63 0px 0px 6px;
			animation: animate4 4s linear infinite;
			animation-delay: 4s;
		}

		@keyframes animate1 {
			0% {
				transform: translateX(-100%);
			}
			100% {
				transform: translateX(100%);
			}
		}

		@keyframes animate2 {
			0% {
				transform: translateY(-100%);
			}
			100% {
				transform: translateY(100%);
			}
		}

		@keyframes animate3 {
			0% {
				transform: translateX(100%);
			}
			100% {
				transform: translateX(-100%);
			}
		}

		@keyframes animate4 {
			0% {
				transform: translateY(100%);
			}
			100% {
				transform: translateY(-100%);
			}
		}
	}

	.info {
		color: #f8deff;
		font-size: 0.9rem;
		line-height: 1.5;
		font-family: "Space Grotesk", sans-serif;
		display: grid;
		justify-items: start;

		padding: 1rem 2rem;
		.moreinfo {
			display: flex;
			width: 100%;
			p {
				margin-left: 5px;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.header {
		h1 {
			font-size: 2rem;
			-webkit-text-stroke: 1px $primary-color;
		}
		.countdown {
			.time-box {
				font-size: 1rem;
				padding: 1.5rem 1.5rem 0.6rem 1.5rem;
				-webkit-text-stroke: 1px $primary-color;
				.subtext {
					font-size: 0.8rem;
				}
			}
		}
	}

	.main-content {
		.info {
			padding: 1rem;
		}
		.status-container {
			padding: 1rem;
			width: 100%;
			h1 {
				font-size: 1rem;
			}
			p {
				font-size: 0.5rem;
			}
		}

		.email-section {
			display: grid;
			justify-content: center;
			padding: 2rem 5em;
			.email-input {
				margin: 0px;
			}
		}
	}
}

@media screen and (max-width: 530px) {
	.header {
		h1 {
			font-size: 2rem;
			padding: 1rem;
		}
		.countdown {
			.time-box {
				font-size: 1.2rem;
				padding: 0.7rem 0.7rem 0.3rem 0.7rem;
				.subtext {
					font-size: 0.8rem;
				}
			}
		}
	}

	.main-content {
		.status-container {
			padding: 0;
			width: 100%;
			h1 {
				font-size: 1rem;
			}
			p {
				font-size: 0.5rem;
			}
		}

		.email-section {
			margin-top: 1rem;
			width: 100%;
			display: grid;
			padding: 0rem;
			.email-input {
				margin: 0px;
			}
			.subscribe-button {
				font-size: 0.7rem;
				width: 100%;
			}
		}
	}
}
